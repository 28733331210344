import React from 'react';
import Header from "../components/header/Header";
import Banner from "../components/banner/Banner";
import Main from "../components/Main";
import Footer from "../components/footer/Footer";
import SEO from "../components/seo";
/**
 * This is the Gatsby index page component.
 *
 */
const Index = () => {

	return (
		<div>
			<SEO title="IT Konsulter"
					 description="Tiebreaker Consulting AB är ett konsultbolag med kompetens inom systemutveckling, systemarkitektur, testautomatisering och continuous delivery."/>
			<section>
			<Header/>
				<main>
					<Banner/>
					<Main/>
				</main>
				<Footer/>
			</section>


		</div>
	);
};
export default Index;

